//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

$opacity-gray-3:             rgba(222,222,222, .3) !default;
$opacity-gray-5:             rgba(222,222,222, .5) !default;
$opacity-gray-8:             rgba(222,222,222, .8) !default;

$opacity-5:                  rgba(255,255,255, .5) !default;
$opacity-8:                  rgba(255,255,255, .8) !default;

$opacity-1:                  rgba(255,255,255, .1) !default;
$opacity-2:                  rgba(255,255,255, .2) !default;

//== Components
//

$topbar-x:             topbar-x !default;
$topbar-back:          topbar-back !default;
$bottombar-x:          bottombar-x !default;
$bottombar-back:       bottombar-back !default;

// Sidebar variables
$sidebar-width:              calc(100% - 235px) !default;
$sidebar-mini-width:         calc(100% - 80px) !default;
